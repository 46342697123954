@import './variables.scss';
.referral-view{
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100vw;
    background-color: $white-11;

    .cta-container{
        display: flex;
        background: $light_blue_gradient;
        position: relative;
        padding: 56px 96px 0px;
        width: 100%;
        max-height: 100vh;
        justify-content: center;
        box-sizing: border-box;
        
        .inner-container{
            display: flex;
            justify-content: center;
            width: 100%;
            box-sizing: border-box;
            height: fit-content;
            align-self: center;
            overflow: hidden;

            .referral-illustration{
                top: 0;
                height: 100%;
                position: absolute;
                &.left{
                    position: absolute;
                    left: calc(min(-50%, -700px));
                    right: 0;
                    margin: 0 auto;
                }
                &.right{
                    left: 0;
                    right: calc(min( -40% , -700px));
                    margin: 0 auto;
                }
            }


            .invite-container{
                display: flex;
                flex-direction: column;
                color: $blue-10;
                width: 100%;
                max-width: 603px;
                row-gap: 16px;
                padding-bottom: 88px;
                position: relative;
                text-align: center;


                p{
                    margin: 0 auto;
                    max-width: 100%;
                    padding: 0 72px;
                    text-align: center;
                }

                .join-container{
                    display: flex;
                    flex-direction: column;
                    color: $green-10;
                    margin-top: 44px;
                    padding: 0 92px;
                    justify-content: center;


                    button{
                        padding: 10px 14px;
                        margin: 0 auto 14px;
                    }

                    h4{
                        font-weight: 400 !important;

                    }


                }

                .input-container{
                    display: flex;
                    width: 100%;
                    margin: 16px 0;
                    row-gap: 16px;
                    padding: 0 92px;
                    box-sizing: border-box;
                    justify-content: center;

                    input{
                        width: 100%;
                    }


                }

                .cta-button{
                    width: fit-content;
                    margin: 0 auto;
                }
            }
            
            
        }
    }

    .info-container{
        display: flex;
        flex-direction: column;
        max-width: 667px;
        width: fit-content;
        margin: 0 auto;

        text-align: center;
        padding: 40px 0 48px;

        h2{
            font-weight: 500 !important;
        }

        p{
            margin-bottom: 16px;
        }

        .rewards-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
            justify-content: center;
            height: 46px;
            box-sizing: border-box;
            column-gap: 24px;
            margin: 24px 0 10px;

            .reward-info{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;

                span{
                    color: $gray-11;
                    margin: 0 5px 0 12px;
                    font-size: 30px;
                    line-height: 30px;
                    font-weight: 700 !important;
                }

                h4{
                    font-weight: 500 !important;
                    color: $gray-10;
                }
            }

            .divider{
                height: 100%;
                width: 2px;
                background-color: $gray-8;
                position: relative;
            }
        }

        .c1{
            color: $gray-10;
            width: fit-content;
            align-self: center;

            b{
                color: $gray-11;
            }
        }
    }

    .referrals-container{
        display: flex;
        flex-direction: column;
        padding: 0 160px 120px ;
        height: fit-content;

        table{
            position: relative;
            width: 100%;
            border-radius: 4px;
            table-layout: fixed;
            border: 1px solid $gray-9  !important;
            margin-top: 16px;
            padding: 0;
            border-spacing: 0;
            box-sizing: border-box;

            .table-header-row.mobile{
                display: none;
            }
            tr{
                th{
                    background-color: $gray-7;
                    font-weight: 700;
                    color: $gray-12 !important;
                }

                td{
                    background-color: $white-10;
                    border-bottom: 1px solid $gray-9 !important;

                   

                }

                th, td{
                    width: 33.33%;
                    text-align: center;
                    padding: 16px 40px;
                    font-size: 18px;
                    line-height: 24px;
                    color: $black-10;
                    &:nth-child(1){
                        text-align: left;
                    }
                    
                }

                &:last-child{
                    th,td{
                        border-bottom: none !important;
                    }
                }
            }
        }
        .empty-table{
            padding: 101px 0;
            width: 100%;
            text-align: center;
            background-color: $white-10;
            box-sizing: border-box;
            border: 1px solid $gray-9;
            border-top: none;
            border-radius: 0 0px 4px 4px;

            h4{
                color: $gray-12;
                font-weight: 400 !important;
            }
        }
    }

    .how-container {
        display: flex;
        flex-direction: column;
        padding: 36px 0 56px;
        width: 100%;
        background-color: $white-12;

        align-items: center;

        .how-steps {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            max-width: 870px;
            column-gap: 66px;

            .step-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin-top: 32px;
                width: 238px;

                img {
                    height: 100px;
                    width: 100px;
                    margin-bottom: 10px;
                }

                h4 {
                    margin-bottom: 12px;
                }
            }
        }
    }

    @media screen and (min-width: 0) and (max-width: $mobile) {
        .cta-container{
            padding: 24px 24px 0;

            .inner-container{
                justify-content: center;
                img{
                    display: none;
                }

                .invite-container{
                    align-self: center;
                    padding-bottom: 24px;
                    p{
                        padding: 0;
                    }
                    .input-container{
                        padding: 0;
                    }
                    input{
                        width: 100%;
                    }
                }
            }
        }

        .info-container{
            padding: 24px 20px;
            .rewards-container{
                flex-direction: column;
                align-items: center;
                row-gap: 12px;
                margin: 24px 0 12px;
                height: fit-content;
                

                .reward-info{
                    align-items: center;
                }

                .divider{
                    display: none;
                }
            }
            
        }

        .how-container{
            .how-steps{
                flex-direction: column;
            }
        }

        .referrals-container{
            padding: 24px 20px;
            table{
                border-collapse: separate; 
                line-height:12px;
                border-radius: 0 0 8px 8px;
                border: 1px solid $gray-8;
                overflow: hidden;
            
                .table-header-row{
                    display: none;

                    &.mobile{
                        display: initial;
                        text-align: center;
                    }
                }

                tr{
                    display: block;
                    float: left;
                    width: 100%;
                    border-bottom: 1px solid $gray-8;
                    border-top: none;

                    &:nth-child(2){
                      
                      border: 1px solid $gray-7;
                    }
                    &:last-child{
                        border: none;
                    }
                    

                   
                    td, th{
                        display: block;
                        box-sizing: border-box;
                        width: 100%;
                        text-align: center !important;
                        padding: 6px 0;
                        border: none !important; 

                    }
                }
            }
        }
    }

    @media screen and (min-width: ($mobile + 1)) and (max-width: $laptop){
        .cta-container{
            padding: 24px 24px 0;

            .inner-container{
                .invite-container{
                    padding-bottom: 24px;
                }
                .example-img{
                    max-height: 500px;
                }
            }
        }
    }
}