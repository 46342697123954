@import './variables.scss';
.footer{
    width: 100vw;
    height: 36px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    background-color: $white-11;
    border-top: 1px solid $gray-9;
    z-index: 1;
    align-items: center;
    padding: 8px 16px;
    box-sizing: border-box;
    color: $gray-11;

    a, .c2{
        color: $gray-11;
    }
    a:visited{
        color: $gray-11 !important;
    }
    a:hover{
        color: $black-10;
    }

    .footer-tm{
        display: flex;
        flex-wrap: nowrap;
        width: fit-content;
        column-gap: 15px;

        align-items: center;

        .footer-language-button{
            display: flex;
            flex-wrap: nowrap;
            column-gap: 4px;
            width: fit-content;
            align-items: center;
            border: none;
            border-radius: 2px;

            &:hover,&:focus{
                span{
                    color: $black-10;
                }
                .globe-stroke{
                    stroke: $black-10;
                }
            }
        }

        .footer-language-menu{
            position: absolute;
            bottom: calc(100% + 6px);
            background-color: $white-11;
            padding: 8px 10px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            align-items: flex-start;
            box-shadow: $small-shadow;

            .language-menu-item{
                border-radius: 0;
                width: 100%;
                padding: 2px 4px;
                border: none;
                
            }
            
        }
    }

    .footer-legal{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 36px;

        a:hover, a:focus{
            color: $black-10 !important;
            .footer-link{
                fill: $black-10;
                cursor: pointer;
           }
        }
    }

    .footer-contact{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 18px;

        .footer-social{
            display: flex;
            column-gap: 16px;
            flex-wrap: nowrap;
        }

        a:hover, a:focus{
            color: $black-10 !important;
            .footer-link{
                fill: $black-10;
                cursor: pointer;
            }
        }
    }

    @media screen and (min-width: 0) and (max-width: $mobile) {
        position: relative;
        height: fit-content;
        margin-top: auto;
        

        .foorter-tm{
            align-self: flex-start;
            margin-bottom: auto;
        }
        
        .footer-legal{
            flex-direction: column;
        }

        .footer-contact{
            flex-direction: column;

            .footer-social{
                column-gap: 8px;
            }
        }
    }
}