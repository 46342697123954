@import '../variables.scss';
.onboarding-view{
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    background-color: $blue-10;
    background-image: url('https://cc-cdn.pss.co/assets/onboarding_bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    
    .onboarding-container{
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: $white-10;
        border-radius: 20px 0 0 20px;
        width: 65%;
        height: 100%;
        margin-left: auto;
        flex-shrink: 0;
        top: 0;
        right: 0;
        z-index: 1;
        max-height: 100vh;
        overflow-y: scroll;

        .onboarding-header{
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding: 36px;
            height: 36px;
            .logo{
                position: relative;
                cursor: pointer;
                height: 36px;
                align-self: flex-start;
            }

            .c1 {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            .go-home{
                width: fit-content;
                padding: 0 16px
            }
        }
    }


    .onboarding-info{
        position: relative;
        width: fit-content;
        max-width: 440px;
        margin: auto;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        align-items: center;
        padding: 60px 0;

        h2{
            text-align: center;
        }

        &.full{
            width: 60%;
        }

        button{
            margin-top: 33px;
        }

        .price-container{
            height: 60px;
            display: flex;
            flex-direction: row;
            color: $black-10;

            span{
                font-size: 30px;
                line-height: 33px;
                color: $gray-12;

                &:last-child{
                    align-self: flex-end;
                }
            }
        }
    }

    @media screen and (min-width: 0) and (max-width: $tablet) {
        background-image: none;
        background-color: $white-10;
        align-items: center;

        .onboarding-container{
            width: 100%;
            position: relative;
            height: 100%;
            border-radius: 0;
            justify-content: unset;
            flex-direction: column;
            padding-bottom: 32px;
            box-sizing: border-box;
            
           .onboarding-header{
               display: flex;
               width: 100%;
               box-sizing: border-box;
               padding: 24px;
               height: fit-content;
               background-color: $white-10;
               z-index: 1;
               position: relative;
           }

            .onboarding-info{
                width: 100%;
                padding: 24px 24px 0;
                box-sizing: border-box;
                justify-content: center;
                height: fit-content;

                h2{
                    width: fit-content;
                    align-self: center;
                    margin-bottom: 24px;
                }

                .cta-button{
                    width: 100%;
                }

                .input-container{
                    width: 100%;
                }

                input, select{
                    width: 100%;
                }
            }
        }
    }
}