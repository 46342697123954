@import './variables.scss';

.success-view{
    position: relative;
    height: fit-content;
    width: 100vw;
    height: 100vh;
    display: flex;

    .success-container{
        width: fit-content;
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 80px auto 0;
        height: fit-content;
        align-items: center;
        text-align: center;

        img{
            max-width: 210px;
        }
        
        p{
            margin: 16px 0 32px;
            color: $gray-13;
        }

    }

}