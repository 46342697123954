/***** Colors *****/
//black
$black-8: #484848;
$black-10: #222222;

//white
$white-10: #FFFFFF;
$white-11: #FAFAFA;
$white-12: #F3F3F3;
$white-13: #F0F0F0;

//gray
$gray-7: #EAEAEA;
$gray-8: #DDDDDD;
$gray-9: #C9C9C9;
$gray-10: #9D9D9D;
$gray-11: #888888;
$gray-12: #6D6D6D;
$gray-13: #636060;

//red
$red-9: #F93C3C;
$red-10: #E83E19;

//coral
$coral-10: #E65855;

//pink
$pink-9: #FF6678;
$pink-10: #FF5862;
$pink-11: #FF4567;
$pink-12: #FE264D;

//blue
$blue-3: #E8F5FF;
$blue-9: #2A72FF; 
$blue-10: #1755CB;
$blue-11: #154FBC;
$blue-12: #253468;
$blue-13: #202F63;

//purple
$purple-9: #613EEB;

//teal
$teal-4: #F2F9F9;
$teal-5: #DCFDFF;
$teal-6: #CAFFFF; 
$teal-7: #CCF3FF;
$teal-8: #0DD2ED;
$teal-9: #00B4CD;
$teal-10: #00A4C8;

//green
$green-8: #41C75F;
$green-9: #1FB240;
$green-10: #009A47;
$green-11: #017544;

//yellow
$yellow-9: #FFDE68;
$yellow-10: #FFC736;
$yellow-11: #FCB500;

//orange
$orange-9: #FF6916;

//gold
$gold-9: #FFE351;
$gold-10: #FCB500;
$gold-12: #FC9700;
$gold-13: #FA8802;

//brown
$brown-10: #C1753E;
$brown-12: #884B1E;

/***** End Colors *****/

/***** Gradients *****/
//product gradients
$pm_gradient: linear-gradient(89.17deg, #FF4D35 0.72%, #ED4870 99.29%);
$mc_gradient: linear-gradient(90deg, #313D67 0%, #174BAE 100%);
$cl_gradient: linear-gradient(262.06deg, #1BAF8B 2.4%, #01A9BD 96.29%);

//right gradients
$green_gradient: linear-gradient(89.3deg, #1FB240 0.63%, #009A47 99.4%);
$teal_green_gradient: linear-gradient(89.94deg, #00A4C8 0.05%, #1FB240 99.95%);
$teal_blue_gradient: linear-gradient(89.3deg, #0098C8 0.63%, #2A72FF 99.4%);
$purple_gradient: linear-gradient(149.16deg, #724FFC 0%, #2A0E99 100%);
$light_blue_gradient: linear-gradient(90.17deg, #D4EAFF 0.05%, #F7F5FF 99.77%);
$blue_purple_gradient: linear-gradient(260.59deg, #613EEB 7.11%, #2A72FF 92.89%);
//down gradients
$blue_down_gradient:linear-gradient(180deg, #1755CB 0%, #1F4BA0 100%);

/***** End Gradients *****/

/*screen sizes*/
$min-width: 320px;
$mobile: 767px;
$tablet: 1023px;
$laptop: 1199px;
$large-screen: 1439px;

$shadow: 0px 21px 60px rgba(50, 48, 48, 0.2);
$small_shadow: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
$small-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
$medium-shadow: 0px 16px 64px rgba(0, 0, 0, 0.12);
$big_shadow: drop-shadow(0px, 16px, 64px rgba(0,0,0,24));
$drop_shadow: drop-shadow(0px 21px 60px rgba(50, 48, 48, 0.2));