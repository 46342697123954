@import '../variables.scss';
@import '../_base.scss';

.subscription{

    .onboarding-header{
        .free-account{
            font-weight: 500 !important;
            border: none;
            color: $blue-10;

            &:hover{
                color: $blue-12;
            }
        }
    }
    .onboarding-info{
        row-gap: 0 !important;
        max-width: 380px;
        position: relative;

        h3{
            width: fit-content;
        }

        .selection-container.subscription{
            margin: 21px 0 18px;
            .c1 {
                &:first-child{
                    color: $blue-10;
                }
                &:last-child{
                    color: $green-10;
                }
        
                &.unselected {
                    color: $gray-10;
                }
            }
        }

        .subscription-illustration{
            max-width: 100%;
        }

        .subscription-features{
            margin: 12px 0 8px;
            align-self: flex-start;
        }

        ul{
            align-self: flex-start;
            margin: 0 0 16px;
            padding-left: 1.75em;

            li{
                color: $gray-13;
                @extend .p2;
            }
        }

        .subscription-pricing{
            &.monthly{
                background: $blue_purple_gradient;
            }
            &.yearly{
                background: $green_gradient;
            }

            &.monthly, &.yearly{
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .subscribe-button{
            margin: 14px 0 12px;
        }

        .caption-info{
            display: flex;
            flex-direction: row;
            text-align: center;
            align-self: flex-end;
            min-width: calc(188px * 2);
            justify-content: center;
            color: $gray-12;

            a {
                color: $blue-10;
                text-decoration: underline;
            }
        }

        .folio-legal{
            position: absolute;
            bottom: 16px;
            color: $gray-12;
            a {
                color: $gray-9;
                text-decoration: underline;
            }
            
        }

        .free-account-mobile{
            display: none;
            margin: 0;
            margin-bottom: 12px;
            border: none;
            color: $blue-10;
            font-weight: 500 !important;
        }

    }
    @media screen and (min-width: 0) and (max-width: $mobile) {
        
        .onboarding-header{
            .free-account{
                display: none;
            }
        }
        .onboarding-info{
            padding: 0 16px !important;
            .free-account-mobile {
                display: flex;
            }

            .folio-legal{
                position: relative;
                bottom: unset;
            }
        }
    }
}