@import './variables.scss';
.content-section{
    position: relative;
    padding: 32px 0 48px;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    .section-title{
        color: $black-8;
        font-weight: 500 !important;
    }

    .content-row{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        position: relative;
        width: 100%;
        align-items: center;
        height: fit-content;
        overflow-x: scroll;
        gap: 25px 32px;
        padding: 24px 0 0;
        box-sizing: border-box;
    }

     @media screen and (min-width: 0) and (max-width: $tablet) {
        padding: 12px 0 12px 12px;
    }

    @media screen and (min-width: 0) and (max-width: $mobile) {
        gap: 8px 10px;
    }
}

.content-object{
    position: relative;
    display: flex;
    flex-direction: column;
    color: $gray-13;
    width: fit-content;
    border: none;
    cursor: pointer;
    padding: 0;
    height: fit-content;

    .multi-line-label{
            align-self: flex-start;
        }
    .resource-container{
        display: flex;
        width: fit-content;
        height: 248px;
        align-items: center;
        margin-bottom: 8px;

        .resource-item{
            display: flex;
            width: fit-content;
            height: fit-content;
            justify-content: center;
            align-items: center;
            position: relative;

            .resource-thumbnail{
                width: 248px;
                height: 248px;
                object-fit: contain;
                user-drag: none;
                -webkit-user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
            
        }
    }
}



.collection-container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    align-items: flex-start;

    .collection-object{
        position: relative;
        display: flex;
        flex-direction: column;
        color: $white-10;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width:248px;
        height: 248px;
        justify-content: space-between;

        text-align: left;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 !important;
        margin-bottom: 10px;

            .collection-thumbnail{
                width:248px;
                height: fit-content;
                object-fit: contain;
                user-drag: none;
                -webkit-user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        
    }
}

.new-badge{
    position: absolute;
    z-index: 1;
    top: -11px;
    right: -9px;
    height: 42px;
    width: 42px;
    text-align: center;
    display: flex;
    align-items: center;

    .new-badge-text{
        z-index: 1;
        position: relative;
        color: $white-10;
        font-size: 11.7px;
        line-height: 1em;
        font-weight: 800;
        width: 100%;
        height: fit-content;
        transform: rotate(12.04deg);
        z-index: 1;
    }

    .new-badge-img{
        animation: spin 12s linear 0s infinite reverse;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
}


@media screen and (min-width: 0) and (max-width: $tablet) {
    .content-object{
        p{
            max-height: 2.5em;
        }
    }
}


@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-359deg);
    }
}