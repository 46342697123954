@import './variables.scss';

html{
    scroll-behavior: smooth;
}
#root{
    font-family: "Mier A";
    box-sizing: content-box;
    max-width: 100vw;
    background-color: $white-12;
    color: $black-10;
    min-height: 100vh;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: env(safe-area-inset-bottom);
}

h1,h2,h3,h4,h5,h6,p{
    margin: 0;
}

h1{
    font-size: 64px;
    line-height: 70px;
    @extend .bold;
}

h2{
    font-size: 48px;
    line-height: 52px;
    @extend .bold;

}

h3{
    font-size: 36px;
    line-height: 40px;
    @extend .bold;

}

h4{
    font-size: 24px;
    line-height: 30px;
    @extend .bold;

}

h5{
    font-size: 20px;
    line-height: 22px;
    @extend .bold;

}

h6{
    font-size: 17px;
    line-height: 20px;
    @extend .book;
}

p{
    font-size: 28px;
    line-height: 36px;
    @extend .reg;
}

.p2{
    font-size: 18px;
    line-height: 24px;
    @extend .reg;
}

.reg{
    font-weight: 400 !important;
}

.book{
    font-weight: 500 !important; 
}

.bold{
    font-weight: 700 !important;
}
.c1{
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    width: 100%;
    @extend .book;
}

.c2{
    font-size: 15px;
    line-height: 20px;
    @extend .book;
}

a{
    text-decoration: none;
    &:visited {
        color: $blue-9;
    }

    &:hover{
        color: $blue-10;
        cursor: pointer;

    }
}

.multi-line-label {
  display: inline-block;
  box-sizing: border-box;
  white-space: pre-line;
  word-wrap: break-word;
  
    .text-max-line {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        width: fit-content;
        color: $black-8 !important;
        max-width: 190px;
        height: 2.57em;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
    }
}

.count-badge{
    position: absolute;
    color: $white-10;
    font-size: 12px;
    line-height: 18px;
    width: fit-content;
    height: 20px;
    box-sizing: border-box;
    z-index: 2;
    min-width: 20px;
    padding: 0 6px;
    background-color: $pink-9;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid $pink-11;

    @extend .bold;
}


button{
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 8px;
    width: fit-content;
    flex-shrink: 0;
    font-family: "Mier A";
    font-size: 18px;
    display: flex;
    line-height: 20px;
    @extend .bold;
    align-items: center;
    filter: $small_shadow;

    &:focus{
        outline: none !important;
    }

    &:hover{
        cursor: pointer;
    }

    &.white{
        border: 1px solid $blue-10;
        color: $blue-10;  
        
        &:hover{
            background-color: $blue-3;
        }
    }
    &.pink{
        border: 3px solid $pink-10;
        color: $pink-10;

        &:hover{
            background-color: $pink-10;
            color: $white-10;
        }
    }

    &.green{
        border: 2px solid $green-10;
        color: $white-10;
        background-color: $green-9;

        &:hover{
            background-color: $green-10;
            color: $white-10;
        }
    }

    &.blue{
        border: 2px solid $blue-10;
        color: $white-10;
        background-color: $blue-9;
        &:hover{
            background-color: $blue-10;
        }
    }

    @media screen and (min-width: 0) and (max-width: $mobile) {
        font-size: 14px;
        line-height: 18px;
    }
}

.default-button{
    border-radius: 4px !important;
    padding: 7px 14px 12px; 
    box-shadow: $small-shadow;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
}

.input-container{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-weight: 700 !important;
    font-family: "Mier A";

    @extend .c2;

    input, textArea{
        width: 288px;
        height: 44px;
        border: 1px solid $gray-9;
        padding: 10px 12px 12px;
        font-size: 18px;
        line-height: 20px;
        color: $gray-13;
        border-radius: 4px;
        box-sizing: border-box;
        font-family: "Mier A";

        @extend .reg;

        &:focus-visible{
            outline-color: $blue-10;
        }

        &::placeholder{
            color: $gray-9;
        }
    }

    textArea{
        height: 100px;
    }

    select{
        width: 288px;
        border-color: $gray-9;
        color: $black-10;
        @extend .reg;

        &:focus{
            min-width: unset;
            width: 288px;
        }
    }

    .warning{
        color: $red-10;
        font-size: 13px;
        line-height: 15px;
        display: none;
    }

     .optional{
                    color: $gray-9;
                    font-size: 15px;
                    line-height: 20px;
                }
}

select{
    position: relative;
    background-color: $white-10;
    border: 1px solid $blue-10;
    padding: 10px 26px 10px 12px;
    border-radius: 4px;
    color: $blue-10;
    font-size: 18px;
    line-height: 24px;
    margin-right: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: fit-content;
    background-image: url('https://cc-cdn.pss.co/assets/select_arrow.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) 50%;
    background-size: 10px 5px;
    @extend .bold;

    &:last-child{
        margin-right: 0;
    }

    &:focus{
        min-width: fit-content;
        width: fit-content;
    }

    @media screen and (min-width: 0) and (max-width: $mobile) {
        font-size: 18px;
        line-height: 20px;
        padding: 6px 10px 9px;
        background-size: 12px 7px;
    }
}

input[type=search] {
    -webkit-appearance: none;
}

.quantity-select{
    padding: 5px 28px 5px 5px;
    width: fit-content;
    box-sizing: border-box;
    height: fit-content;
    margin: 0;
    border: 1.5px solid $gray-8;
    height: 48px;
    border-radius: 8px;
    box-shadow: $small-shadow;
    @extend h5;
}

.loading-overlay{
    position: fixed;
    display: initial;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.4);
    overflow: hidden;
    z-index: 10;


    .loading-animation{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100px;
        max-height: 100px;
        background-color: transparent;
    }
}

 .selection-container{
     /* The switch - the box around the slider */
     .switch {
         position: relative;
         display: inline-block;
         width: 49px;
         height: 20px;
         margin: 0 12px;
     }

     /* Hide default HTML checkbox */
     .switch input {
         opacity: 0;
         width: 0;
         height: 0;
     }

     /* The slider */
     .slider {
         position: absolute;
         cursor: pointer;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         background-color: $blue-10;
         -webkit-transition: .4s;
         transition: .4s;
    }

     .slider:before {
        position: absolute;
        content: "";
        height: 15.72px;
        width: 15.72px;
        left: 4px;
        bottom: 2.25px;
        background-color: $white-11;
        -webkit-transition: .4s;
        transition: .4s;
        margin-block: auto;
     }

     input:checked+.slider {
         background-color: $green-8;
     }

     input:focus+.slider {
         box-shadow: 0 0 1px $green-8;
     }

     input:checked+.slider:before {
         -webkit-transform: translateX(26px);
         -ms-transform: translateX(26px);
         transform: translateX(26px);
     }

     /* Rounded sliders */
     .slider.round {
         border-radius: 34px;
     }

     .slider.round:before {
         border-radius: 50%;
     }
 }

.favorite-button{
    border: 1px solid $pink-12;
    height: 28px;
    width: 31px;
    min-width: fit-content;
    padding: none !important;
    color: $white-10;
    display: flex;
    flex-direction: row;
    background-color: $white-10;
    align-items: center;
    justify-content: center;
    filter: $small_shadow;

    h6{
        margin-left: 5px;
        line-height: 19px;
        font-size: 14px;
    }

    &.selected{
        background: linear-gradient(90deg, #FF768F 0%, #FF5862 100%);
    }
}

.cta-button{
    position: relative;
    background: $teal_blue_gradient;
    color: $white-10 !important;
    border: 1px solid $blue-10;
    box-sizing: border-box;
    height: 42px;
    width: 288px;
    font-size: 18px;
    line-height: 20px;
    display: inline-block;

    @extend .bold;

    &:focus, &:hover{
        background: $blue-12
    }
}

.divider{
    position: relative;
    width: 100%;
    height: 1px;
    background-color: $gray-9;
}

.home-row{
    position: relative;
    display: flex;
    width: 100%;
    height: fit-content;
    background-color: $white-12;
    overflow: hidden;
    padding: 0 80px;
    box-sizing: border-box;
    flex-direction: column;

    &:nth-child(even){
      background-color:$white-11;
    }

    &:last-child{
        padding-bottom: 48px;
    }
}

.row-arrow, .cta-arrow{
    position: absolute;
    top:0;
    z-index: 1;
    height: 100%;
    width: 12px;
    background-repeat: no-repeat;
    background-position-y: center;
    border: none;
    display: flex;

    &.left{
        background-image: url('https://cc-cdn.pss.co/assets/row-arrow-left.svg');
        display: none;
        right: calc(100% + 24px);

    }

    &.right{
        background-image: url('https://cc-cdn.pss.co/assets/row-arrow-right.svg');
        left: calc(100% + 24px);
    }
}

.placeholder-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 28px;
    color: $gray-13;

    h4{
        font-weight: 500 !important;
    }

    button{
        height: 48px;
        padding: 0 13px;
    }

    .placeholder-illustration{
        max-width: 580px;
    }
}

 .resource-info-row {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     width: fit-content;
     height: 60px;
     box-sizing: border-box;
     margin: 12px 0 20px 0;
     column-gap: 12px;
     overflow-x: scroll;
     -ms-overflow-style: none;
     /* IE and Edge */
     scrollbar-width: none;
     /* Firefox */

     &::-webkit-scrollbar {
         display: none;
     }

     .info-row-item {
         height: 100%;
         width: fit-content;
         min-width: 60px;
         padding: 0 10px;
         background-color: $gray-7;
         color: $gray-12;
         border: 2px solid transparent;
         border-radius: 8px;
         display: flex;
         flex-direction: column;
         box-sizing: border-box;
         align-items: center;
         justify-content: center;
         flex-shrink: 0;

         .c2 {
             font-size: 14px;
         }

         &.saved,
         &.save:hover {
             border-color: $gold-12;
             font-weight: 500;
             color: $gold-12;
             background-color: $gold-9;

             #star-icon {

                 fill: $white-10;
                 stroke: $gold-12;
             }

         }

         &.share {
             &:hover {
                 color: $blue-10;
                 border-color: $blue-10;
                 background-color: $white-10;


                 .share-icon {
                     stroke: $blue-10;
                 }

             }
         }

         &.subject {
             flex-direction: row;
             font-weight: 500;
             flex-wrap: nowrap;

             &:hover {
                 background-color: $gray-8;
             }
         }
     }
 }

 .image-overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
 }

 #hubspot-messages-iframe-container.widget-align-right{
    bottom: 25px !important;
 }

@media screen and (min-width: 0) and (max-width:$mobile){
    h1{
        font-size: 40px !important;
        line-height: 44px;
    }

    h2{
        font-size: 36px !important;
        line-height: 40px;
    }

    h3{
        font-size: 32px !important;
        line-height: 36px;
    }

    h4{
        font-size: 24px !important;
        line-height: 26px;
    }

    h5{
        font-size: 24px !important;
        line-height: 26px;
    }

    h6{
        font-size: 18px !important;
        line-height: 21px;
    }

    p{
        font-size: 24px !important;
        line-height: 31px;
    }

    .p2{
        font-size:  20px !important;
        line-height: 26px;
    }

    .c1{
        font-size: 18px !important;
        line-height: 23px;
    }

    .c2{
        font-size: 14px !important;
        line-height: 22px;
    }

     .content-row{
      padding: 0 0px;
    }

    .home-row{
        padding: 0 12px;
    }

    #hubspot-messages-iframe-container.widget-align-right {
        bottom: 0px !important;
    }
}

@media screen and (min-width: 0) and (max-width: $tablet) {
    #root{
        height: fit-content;
    }
    .row-arrow, .cta-arrow{
      display: none !important;
    }
    
    .cta-arrow{

        &.left{
            right: calc(100% - 28px);

        }

        &.right{
            left: calc(100% - 20px);
        }
    }

    .placeholder-container{
        padding: 14px;
        box-sizing: border-box;
        
        .placeholder-illustration{
            width: 100%;
        }
    }
}

.mth{
    color: $blue-9;
    border-color: $blue-9;
    background-color: $blue-9;

    &.gradient{
        background: linear-gradient(180deg, #ACD7FF 0%, #E8F5FF 100%);
    }
}
.ela{
    color: $blue-12;
    border-color: $blue-12;
    background-color: $blue-12;

    &.gradient{
        background:  linear-gradient(180deg, #ACD7FF 0%, #E8F5FF 100%);
    }
}
.par{
    color: $gold-13;
    border-color: $gold-13;
    background-color: $gold-13;

    &.gradient{
        background: linear-gradient(180deg, #FFED8D 0%, #FFFBD9 100%);
    }
}
.go{
    color: $purple-9;
    border-color: $purple-9;
    background-color: $purple-9;

    &.gradient{
        background: linear-gradient(180deg, #E3DDF9 0%, #F7F5FF 100%);
    }
}
.sci{
    color: $green-9;
    border-color: $green-9;
    background-color: $green-9;

    &.gradient{
        background: linear-gradient(180deg, #C9EFB2 0%, #E4F4DA 100%);
    }
}
.ell{
    color: $orange-9;
    border-color: $orange-9;
    background-color: $orange-9;
    &.gradient{
        background: linear-gradient(180deg, #FFD8BC 0%, #FEF3EB 100%);
    }
}
.sel{
    color: $coral-10;
    border-color: $coral-10;
    background-color: $coral-10;

    &.gradient{
        background: linear-gradient(180deg, #F8D4D4 0%, #FEF2F2 100%);
    }
}

.gen{
    color: $teal-10;
    border-color: $teal-10;
    background-color: $teal-10;

    &.gradient{
        background: linear-gradient(180deg, #CFF4EB 0%, #F2F9F9 100%);
    }
}


