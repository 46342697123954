@import './variables.scss';

.search-view{
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: $white-12;

    &.fixed{
        height: calc(100vh - 82px);
    }
    
    .search-bar-container{
        position: relative;
        width: 100%;
        height: fit-content;
        top: 0px;
        z-index: 1;
        justify-content: center;
        display: flex;
        max-width: 726px;
        margin: 40px auto 0;
        background-color: $white-12;

        .search-bar{
           
            position: relative;
            border: 1px solid $gray-9;
            border-radius: 8px;
            width: 100%;
            height: 66px;
            margin: 0 auto;
            line-height: 40px;
            font-size: 32px;
            padding: 8px 11px 8px 72px;
            box-sizing: border-box;
            outline-color: $blue-10;
            background: url('https://cc-cdn.pss.co/assets/icons/search.svg') no-repeat left;
            background-size: 36px;
            background-position-x: 22px;
            box-sizing: border-box;
            background-color: $white-10;
            filter: $medium_shadow;

            &:focus{
                border-color: $blue-10;
            }

            &::placeholder{
                color: $gray-10;
            }
        }
    }

    .search-landing{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 50px;
        align-items: center;
        color: $gray-12; 
        box-sizing: border-box;
        justify-content: space-between;
        height: 100%;
        overflow: hidden;
        h3{
            font-weight: 500 !important;
        }
    
        img{
            width: 120%;
            margin-top: 12px !important;
            transform: rotate(-2.13deg);
        }
    }

    .results-container{
        padding: 14px 48px;
        .no-results{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 58px;
            h3{
                font-weight: 400 !important;
                color: $gray-10;
            }
            h4{
                font-weight: 500 !important;
                color: $gray-12;
            }

            img{
                margin: 44px 0;
            }
        }

        .search-results{
            display: flex;
            width: 100%;
            flex-direction: column;
            flex-wrap: wrap;
            column-gap: 24px;
            row-gap: 40px;
            padding: 12px 48px;
            box-sizing: border-box;
            height: fit-content;

            .results-count{
                color: $gray-10;
                padding-bottom: 24px;
                width: 100%;
                border-bottom: 1px $gray-8 solid;

                h4{
                    font-weight: 400 !important;
                }
            }

            .result-items{
                display: flex;
                flex-direction: row;
                width: 100%;
                height: fit-content;
                flex-wrap: wrap;
                row-gap: 40px;
                column-gap: 14px;
            }

        }
    }

    @media screen and (min-width: 0) and (max-width: $mobile) {
        padding: 0;
        box-sizing: border-box;
        height: fit-content;
        min-height: calc(100vh - 70px);

        .search-bar-container{
            padding: 20px;
            box-sizing: border-box;
            margin-top: 0;
        
            .search-bar{
                background-size: 30px;
                background-position-x: 17px;
                padding-left: 59px;
                line-height: 26px;
                font-size: 28px;
            }
        }

        .search-landing{
            padding: 0 20px;
            box-sizing: border-box;
            margin-top: 0;
            position: relative;
            justify-content: space-between;
            height: 100%;
            display: flex;
            flex-grow: 1;
            overflow: hidden;
            h3{
                font-weight: 500 !important;
                text-align: center;
            }
            img{
               position: absolute;
               height: 120%;
               top: 48px;
               width: auto;
            }
        }

        .results-container{
            padding: 0 20px;
            height: 100%;
            .no-results{
                padding: 0 14px;
                text-align: center;
            }
            .search-results{
                row-gap: 0;
                padding: 24px 0;
                justify-content: space-between;
                flex-direction: column;
                position: relative;
                  
                .results-count{
                    padding: 0 0 20px;
                    box-sizing: border-box;
                    margin-bottom: 24px;
                }

                .result-items{
                    display: grid;
                    grid-template-columns:  repeat(2, 1fr);
                    column-gap: 16px;
                    height: 100%;
                    overflow: scroll;
                    .resource-container{
                        height: unset;
                        margin-bottom: 8px;
                        .resource-thumbnail{
                            max-height: unset;
                            max-width: 100%;
                        }
                    }
                }
            }
        }        
    }
}