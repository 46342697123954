 @import './variables.scss';

.header{
    position: relative;
    padding: 0 32px ;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 100%;
    height: 82px;
    z-index: 9;
    top: 0;
    background-color: $white-12;

    .header-left{
        position: relative;
        display: flex;
        flex-direction: row;
        flex: 0 0 auto;
        align-items: center;
        width: fit-content;
        margin-right: 32px;
    
        .header-logo{
            height: 32px;
            display: flex;
            position: relative;
        }

        .header-title{
            display: flex;
            justify-content: center;
            font-weight: 500;
            align-items: center;
            justify-content: center;
            height: fit-content;
            margin-left: 6px;

            width: fit-content;
            background: linear-gradient(89.97deg, #0098C8 0.03%, #2A72FF 51.27%, #724FFC 98.4%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            align-items: flex-end;
        }

        .header-search{
            margin: 12px 0 auto 34px;
            display: flex;
            height: 100%;
            width: 22px;
            align-self: flex-end;
            padding:  0 4px 8px;

            &.selected, &:hover{
                border-bottom: 2px solid $blue-10;

                .search-icon{
                    stroke: $blue-10;
                }
            }
        }
    }

    .header-links{
        display: flex;
        position: relative;
        width: fit-content;
        box-sizing: border-box;
        align-items: center;
        column-gap: 32px;
        a{
            font-size: 20px;
            line-height: 20px;
            font-weight: 500;
            color: $gray-12;
            margin-top: 10px;
            padding-bottom: 12px;

            &:hover, &.selected{
                color: $blue-10;
                border-bottom: 2px solid $blue-10;
            }

            &:last-child{
                margin-right: 0;
            }
        }
    }
    .referral-button{
            height: 44px;
            position: relative;
            width: 44px;
            justify-content: center;
            align-items: center;

            .count-badge{
                top: -11px;
                right: -5px;
            }

            &.dropdown{
                display: none;
            }

    }
    .header-actions{
        position: relative;
        display: flex;
        margin-left: auto;
        column-gap: 16px;
        align-items: center;
        width: fit-content;

        .action-button{
            height: 44px;
            position: relative;
            width: 44px;
            justify-content: center;
            background-size: 29px;
            box-shadow: $small-shadow;
            border: 2px solid $gray-8;
            border-radius: 8px;

            &:hover{
                background-color: $white-11;
            }

            &.referral{
                height: 44px;
                position: relative;
                width: fit-content;
                justify-content: center;
                background-size: 29px;
                box-shadow: $small-shadow;
                color: $blue-10;
                img{
                    margin-left: 8px;
                }

                .count-badge{
                    top: -8px;
                    right: -8px;
                }

                &.dropdown{
                    display: none;
                }
            }
        }

        .header-button{
            padding: 9px 14px;
            height: 44px;
            box-sizing: border-box;
            border-radius: 8px;

            svg{
            
                margin-right: 5px;
            }
        }

        .account-button{
            position: relative;
            display: flex;
            flex: 0 0 auto;
            height: 48px;
            align-items: center;
            width: fit-content;
            border: none;
            padding: 0;
            column-gap: 16px;

            .header-profile{
                border-radius: 50%;
                overflow: hidden;
                height: 44px;
                width: 44px;
                border: 1px solid $blue-10;
                background-color: $blue-9;
                color: $white-10;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                display: flex;
                filter: $small_shadow;

                &.no-profile{
                    background-color: transparent;
                    border-color: $gray-9;
                }

                .p2{
                    font-weight: 700 !important;
                }
                .header-close{
                    display: none;
                    stroke: $white-10;
                    stroke-width: 3;
                    stroke-linecap: round;
                    height: 16px;
                    width: 16px;

                }

                &.open{

                    &.no-profile{
                        background-color: $gray-9;
                        .no-profile-icon{
                            display: none;
                        }
                    }
                    span{
                        display: none;
                    }
                    .header-close{
                        display: flex;
                    }
                }

                &:hover{
                    .header-close{
                        stroke: $white-12;
                    }
                }
            }

            .header-menu{
                height: 100%;
                width: 100%;
                display: none;
                background-image: url("https://cc-cdn.pss.co/assets/icons/menu_open.svg");
                transition-duration: .2s;
                transition-timing-function:  ease-in;

                &.open{
                    background-image: url("https://cc-cdn.pss.co/assets/icons/menu_close.svg");
                }
            }
        }
    }

    .header-dropdown{
        position: absolute;
        top: calc(100% + 8px); 
        right: 14px;
        width: 160px;
        z-index: 5;
        border-radius: 4px;
        filter: $small_shadow;
        padding: 12px 16px;
        flex-direction: column;
        background-color: $white-10;
        row-gap: 8px;
        border: 1px solid $gray-8;
        display: none;
        box-shadow: $small-shadow;

        &.open{
            display: flex;
        }

          .header-links-mobile{
            padding-bottom: 32px;
            box-sizing: border-box;
            border-bottom: 1px solid $gray-8;
            display: none;
            flex-direction: column;
            row-gap: 24px;
        }
        .dropdown-divider{
            width: 100%;
            height: 1px;
            background-color: $gray-9;
        }

        .header-dropdown-item{
            position: relative;
            width: 100%;
            height: fit-content;
            border: none;
            border-radius: 4px;
            font-weight: 500 !important;
            text-align: left;
            filter: none;
            display: inline-block;
            padding: 8px 4px;
            font-size: 16px;
            line-height: 21px;
            &:hover{
                background-color: $white-12;
            }
        }

        .header-mobile-ctas{
            display: none;
        }
    }

    @media screen and (min-width: 0) and (max-width: $tablet) {
        padding: 0 12px;
        justify-content: space-between;
        height: 70px;
        box-sizing: border-box;
        width: 100%;
        max-width: 100vw;

        .header-dropdown {
            width: 100%;
            height: calc(100% - 70px);
            left: 0;
            top: calc(-100vh);
            right: unset;
            margin: 0 auto;
            z-index: 0;
            padding: 24px 40px;
            box-sizing: border-box;
            row-gap: 24px;
            left: 0;
            right: 0;
            border: none;
            border-radius: 0;
            transition-duration: .35s;
            transition-timing-function:  ease-in;
            overflow: scroll;
            position: fixed;
            background-color: $white-12;
            padding-bottom: env(safe-area-inset-bottom);

            display: flex;
            &.open{
               transform: translate(0, calc(100vh + 70px));
            }

            .header-links-mobile{
                display: flex;
            }

            .header-dropdown-item{
                border: none;
                color: $gray-12;
                font-size: 32px;
                line-height: 36px;
                font-weight: 400;
                text-align: left;
                padding: 0;

                &.login {
                    display: none;
                }
            }

            .header-dropdown-button-row{
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                .header-dropdown-button{
                    width: 100%;
                    height: fit-content;
                    text-align: center;
                    font-size: 32px;
                    line-height: 36px;
                    padding: 8px;
                    justify-content: center;
                }
            }

            .referral-button.dropdown{
                display: flex;
                width: 100% !important;
                height: fit-content;
                max-width: none;
                border: none;
                border-bottom: 3px solid $blue-10;
                border-radius: 0;
                padding: 20px 0;
                font-size: 32px;
                line-height: 36px;
                font-weight: 500 !important;

                img{
                    height: 26px;
                    width: 26px;
                    margin-left: 8px;
                }

                .count-badge{
                    position: relative;
                    margin: auto 0;
                }
            }

            .header-mobile-ctas{
                display: flex;
                flex-direction: column;
                row-gap: 12px;
            }
        }


        .header-left{
            position: relative;
            justify-content: space-between;
            width: 100%;
            left: 0;
            right: 0;
            margin: 0;
            z-index: 2;
            order: 0;
            height: 100%;
            box-sizing: border-box;
            flex-shrink: 1;
            background-color: $white-12;
            padding-right: 12px;

            .header-title{
                display: none;
            }

            .header-search{
                margin-left: 0;
            }
        }
        .header-links{
            display: none;
        }

        .header-actions{
            position: relative;
            width: fit-content;
            justify-content: space-between;
            flex-direction: row;
            z-index: 1;
            height: 100%;
            margin-left: 0;
            flex-shrink: 0;
            background-color: $white-12;

            .action-button{
                &.referral{
                    display: none;
                }
            }


            .header-button{
                display: none;
            }
            .account-button {
                width: 36px;
                height: 36px;
                .header-profile{
                    display: none;
                }
                .header-name{
                    display: none;
                }

                 .header-menu{
                    display: flex;
                }
            }
            .referral-button{
                display: none;
            }
        }
    }
}