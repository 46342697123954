@import './variables.scss';
.resources-view{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: fit-content;

    .subject-sidebar{
        display: flex;
        flex-direction: column;
        width: 272px;
        max-width: 272px;
        background-color: $white-13;
        border-right: 1px solid $gray-9;
        box-sizing: border-box;
        flex-shrink: 0;
        height: 100vh;
        position: sticky;
        top: 0;

        .close-button{
            height: fit-content;
            border: none;
            outline: none;
            color: $blue-10;
            padding: 12px;
            box-sizing: border-box;
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: flex-end;
            border-bottom: 1px solid $gray-9;
            display: none;

            .c1{
                width: fit-content;                
            }

        }

        .sidebar-item{
            width: 100%;
            padding: 14px 16px;
            box-sizing: border-box;
            color: $gray-13 ;
            cursor: pointer;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            .sidebar-icon{
                height: 32px;
                width: 32px;
                margin-right: 8px;
            }

            &.selected{
                color: $white-10;
                pointer-events: none;
            }

            &:hover{
                color: $white-10;
            }

            &:not(:hover):not(.selected){
                background-color: $white-13;
            }
        }
    }

    .subject-button{
        position: sticky;
        top: 0px;
        z-index: 1;
        width: 100%;
        color: $white-10;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: left;
        align-items: center;
        display: none;
        border-radius: 0;
        border: none;
        padding: 0 12px;
        box-sizing: border-box;

        .arrow_down, .arrow_up{
            position: relative;
            transition-duration: .35s;
            transition-timing-function:  ease-in-out;
            margin-right: 8px;
        }

        .arrow_up{
            transform: rotate(180deg);
        }

        .sidebar-icon{
            margin-right: 8px;
        }
    }

    .subject-overlay{
        position: fixed;
        display: none;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 4;
        background-color: #C4C4C4;
        mix-blend-mode: multiply;
    }

    .list-container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background: none;
        flex-shrink: 1;
        overflow-y: scroll;
        position: relative;

        .subject-header{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 128px;
            flex-shrink: 0;

            h1{
                padding-bottom: 5px;
            }
        }

        .grade-level-section{
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            height: 96px;
            flex-shrink: 0;
            justify-content: center;
            background-color: $gray-7;


            .grade-level-tab{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: $gray-7;
                width: 100%;
                outline: none;
                filter: none;
                flex-shrink: 1;
                border-radius: 0;
                border: 1px solid $gray-9;
                max-width: 50%;
                margin: 0;

                &:not(:only-child){
                     &:first-child, &:last-child{
                        border-left: none;
                        border-right: none;
                     }
                }
                
                &:not(.active){
                    color: $gray-10;
                }

                &.active{
                    background-color: $white-10 ;
                    border-bottom: none;
                    pointer-events: none;
                }
            }
        }

        .domain-section{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            height: fit-content;
            column-gap: 36px;
            row-gap: 16px;
            background-color: $white-10;
            padding: 20px 80px 28px;
            box-sizing: border-box;
            justify-content: center;
            position: sticky;
            top: 0;
            left: 0;
            z-index: 2;

            .domain-item{
                border: none;
                border-bottom: 1px solid transparent;
                padding-bottom: 4px;
                box-sizing: border-box;
                border-radius: 0px;
                color: $gray-12;
                font-weight: 300;

                &.active{
                    border-color: black ;
                    color: unset ;
                    border-color: unset;
                }

                @media (hover: hover) {
                    &:hover {
                       border-color: black;
                        color: unset;
                        border-color: unset;
                    }
                }
            }
        }

        .resources-list{
            display: flex;
            flex-direction: column;

            .resources-container{
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 0 0;
                box-sizing: border-box;
                height: 100%;
                flex-grow: 1;
                flex-shrink: 1;
                overflow-y: scroll;


                .section-container{
                    display: flex;
                    flex-direction: column;
                    height: fit-content;
                    background-color: $white-10;
                    padding: 28px 48px;
                    box-sizing: border-box;
                    position: relative;


                    h3{
                        font-weight: 300;
                    }

                    .section-resources{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        width: 100%;
                        height: fit-content;
                        column-gap: 24px;
                        row-gap: 40px;
                        padding-top: 24px;
                        box-sizing: border-box;
                    }

                    &:nth-child(even){
                        background-color: $white-11;
                    }
                }

            }
        }
    }

    @media screen and (min-width: 0px) and (max-width: $tablet) {
        height: 100%;
        .subject-sidebar{
            position: fixed;
            top: 100%;
            right: 0;
            left: 0;
            bottom: 0;
            margin: 0 auto;
            max-width: unset;
            width: 100%;
            height: fit-content;
            padding-bottom: 12px;
            box-sizing: border-box;
            z-index: 0;
            transition-duration: .35s;
            transition-timing-function:  ease-in-out;
            border-radius: 8px 8px 0;
            background-color: $white-10;

             &.open{
                z-index: 5;
                transform: translate(0, -100%);
            }
            .close-button{
                display: flex;
            }

            .sidebar-item{
                &.selected{
                    display: none !important;
                }

                 &:not(:hover):not(.selected){
                    background-color: transparent;
                }
            }
        }

        .subject-overlay{
            &.open{
                display: initial;
            }
        }

        .subject-button{
            display: flex;
        }

        .list-container{
            height: 100%;
            overflow-y: initial;
            .resources-list{
                padding-bottom: 64px;
                box-sizing: border-box;
                .resources-container{
                    .section-container{
                        padding: 16px 20px;
                        box-sizing: border-box;
                        .section-resources{
                            width: 100%;
                            box-sizing: border-box;
                            overflow-x: scroll;
                            flex-wrap: nowrap;
                            height: fit-content;
                            column-gap: 16px;
                        }
                    }
                }
            }
             .domain-section{
                display: none;
            }
        }
    }

    @media screen and (min-width: 0px) and (max-width: $mobile) {
        .list-container{
            .subject-header{
                display: none;
            }

            .grade-level-section{
                padding: 12px 12px 12px 24px;
                column-gap: 16px;
                box-sizing: border-box;
                width: 100%;
                overflow-x: scroll;
                background-color: $white-11 !important;
                justify-content: space-between;
                position: sticky;
                top: 50px;
                z-index: 1;
                height: fit-content;
                border-bottom: 1px solid $gray-9;
                .grade-level-tab{
                    width: fit-content;
                    border-radius: 0;
                    border: none;
                    height:fit-content;
                    border-bottom: 2px solid !important;
                    padding-bottom: 7px !important;
                    position: relative;
                    background-color: transparent;
                    

                    &.active{
                        background-color: $white-11 ;
                    }

                    h3{
                        font-size: 17px !important;
                        line-height: 20px !important;
                        font-weight: 400 !important;
                    }

                    .c2{
                        display: none;
                    }
    
                }
            }
        }
    }
}