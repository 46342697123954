@import './variables.scss';

.home{
  position: relative;
  width: 100%;
  background-color: $white-10;
  display: flex;
  flex-direction: column;
  .home-row{
    &.new{
      background: linear-gradient(0deg, #D4EAFF 0%, #E3DDF9 100%);

      .section-title{
        background: linear-gradient(260.59deg, #613EEB 40%, #2A72FF 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
        
    }
    .ctas-container{
      position: relative;
      width: 100%;
      max-width: 960px;
      height: fit-content;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 0 auto;
      padding: 40px 0 18px;
      justify-content: space-between;
      box-sizing: border-box;
      

      .ctas-row{
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: hidden;
        margin: 0 auto;
        padding: 0 0px;
        justify-content: space-between;
        column-gap: 96px;
        box-sizing: border-box;

        .cta-item{
          position: relative;
          display: flex;
          width: 100%;
          max-height: 100%;
          flex-shrink: 0;
          padding: 0 0px;
          border-radius: 8px;
          border: none;
        }
      }
    }
    .cta-dots-row{
      position: relative;
      display: flex;
      width: fit-content;
      height: fit-content;
      margin: 0 auto;
      column-gap: 24px; 
      margin-bottom: 18px;
        .cta-dot{
          background-color: $gray-9;
          height: 14px;
          width: 14px;
          border-radius: 50%;
          cursor: pointer;          

          &.active{
            background-color: $blue-10;
            pointer-events: none;
          }
        }
    }
  }

  .promo-container{
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 40px 0;
    box-sizing: border-box;
    max-width: 1200px;

    h3{
      margin-bottom: 32px;
    }

    .promo-item{
      position: relative;
      display: flex;
      height: fit-content;
      width: 100%;
      max-width: 1056px;
      border-radius: 8px;  
      overflow: hidden;
      cursor: pointer;
      align-self: center;
      
      img{
        max-width: 100%;
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        
      }
    
    }
  }
  
  .cta-img{
    width: 100%;
    border-radius: 8px;

  }
  .cta-img-mobile{
      display: none;
      border-radius: 8px;
    
  }

  @media screen and (min-width: 0) and (max-width: $mobile) {

    .home-row{
      padding: 20px;

      .content-section{
        padding: 0;
      }
      .ctas-container{
        height: auto;
        width: 100%;
        max-width: unset;
        padding: 24px 0 10px !important;

        .ctas-row{
          padding: 0 0 18px !important;
          height: fit-content;
          overflow-x: scroll;
          column-gap: 24px;
          margin-right: 0;

          .cta-item{
            width: 80% ;

            .cta-dot{
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .cta-dots-row{
        display: none;
        margin-bottom: 18px;
      }
      
      .promo-container{
        padding: 24px 12px;
      }

      .cta-img{
        display: none !important;
      }
      .cta-img-mobile{
        display: initial;
        height: auto;
        max-height: unset;
        width: 100%;
      }
    }
  }
}