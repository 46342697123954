@import './variables.scss';
.list-view{
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding: 32px 69px;
    background-color: $white-12;
    box-sizing: border-box;
    flex: 1 1 auto;

    .list-header{
        position: relative;
        display: flex;
        align-items: flex-end;
        width: 100%;
        overflow: hidden;
        padding: 16px 0 8px;
        box-sizing: border-box;

        .list-counter{
            color: $gray-13;
            margin-left: auto;
        }

        .filter-button{
            display: none;
            position: relative;
            background-color: $white-10;
            color: $blue-10;
            border: 1px solid $blue-10;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            padding: 6px 10px 9px;
            justify-content: space-between;
            align-items: center;
            column-gap: 12px;
            &:focus{
                background: $teal_blue_gradient;
                color: $white-10;
            }
        }
    }

    .list-container{
        position: relative;
        width: fit-content;
        max-height: 100%;
        min-width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-items: center;
        align-items: flex-start;
        padding-top: 16px;
        padding-bottom: 48px;
        overflow-y: scroll;
        box-sizing: border-box;
        align-items: flex-end;
        gap: 25px 32px;
        justify-content: flex-start;
    }

    .no-results{
        position: relative;
        width: fit-content;
        height: fit-content;
        margin: auto;
        background-color: $white-10;
        padding: 24px;
        border-radius: 4px;
        max-width: 350px;
        text-align: center;
        margin-top: 15%;
       
        
        h2{
            margin-bottom: 8px;
        }
    }

    @media screen and (min-width: $mobile + 1px) and (max-width: $tablet) {
        .list-container{
            justify-content: flex-start;
        }
    }
    @media screen and (min-width: 0) and (max-width: $mobile) {
        .list-header .filter-button{
            display: flex;
        }

        .list-header, .list-container{
            max-width: 375px;
            margin: 0 auto;
        }
    }
    @media screen and (min-width: 0) and (max-width: $tablet) {
        padding: 0;
        box-sizing: border-box;
        
        .list-header{ 
            justify-content: flex-start;
            flex-wrap: wrap;
            column-gap: 8px;
            padding: 12px 12px 24px;

            .list-counter{
                margin-top: 4px;
                width: 100%;
                text-align: right;
            }
        }
        .list-container{
            padding: 0 14px 90px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 16px;
            overflow-y: scroll;
            width: 100%;
            box-sizing: border-box;
            justify-content: space-between;
            height: fit-content;

            &.collection-list,&.favorites-list{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center
            }

            .lazyload-wrapper{
                display: flex;
                width: calc(50% - 8px);

            }
            
            .collection-object{
                max-width: 100%;
                max-height: unset;
                height: fit-content;
                width: 100%
            }

            .content-object, .collection-object{
                align-items: center;
                justify-content: center;
                    
                    .resource-container{
                        height: unset;
                        margin-bottom: 8px;
                    }
                    .resource-thumbnail, .collection-thumbnail{
                        max-width: 100%;
                        max-height: unset;
                        width: 100%
                    }

                .multi-line-label{
                    align-self: center;
                    .text-max-line{
                        text-align: center;
                    }
                }
            }
        }
    }
}
