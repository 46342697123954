@import './variables.scss';

.not-found-view{
    position: relative;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 24px;
    text-align: center;

    a{
        color: $blue-10 !important;
    }
}