@import './variables.scss';
.collection-view{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $white-12;

    .favorite-button{
        align-self: flex-start;
    }

    .collection-container{
        position: relative;
        width: 75%;
        max-width: 966px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding-bottom: 64px;
        align-items: flex-start;

        .collection-title{
            position: relative;
            align-self: flex-start;
            margin-bottom: 24px;
        }

        .collection-header{
            position: relative;
            border-radius: 8px;
            margin-bottom: 12px;
            width: 100%;
            display: flex;
            height: fit-content;
            overflow: hidden;


            .collection-header-img{
                position: relative;
                width: 100%;
                height: auto;

                &.mobile{
                    display:none
                }
                
            }
        }

        .resource-info-row{
            margin: 0;
        }

        .divider{
            margin: 36px 0 32px;
        }
        .collection-resources-container{
            position: relative;
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            border-bottom: 1px solid $gray-9 ;
            border-top: 1px solid $gray-9;
            width: 100%;
            margin: 12px 0 32px;
            padding: 32px 0 48px;

              .collection-resources-list{
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin: 32px 0 0;
                width: 100%;
                gap: 32px;
            }

        }

        .collection-details{
            position: relative;
            display: flex;
            flex-direction: row;
            width: 100%;
            flex-wrap: nowrap;
            height: fit-content;
            column-gap: 46px;

            h4{
                font-weight: 500 !important;
            }

            .collection-info{
                position: relative;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                width: 100%;
                row-gap: 8px;
            }

            .collection-description{
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                row-gap: 12px;
            }
        }
    }

    @media screen and (min-width: 0) and (max-width: $tablet) {
        .collection-container{
            .collection-details{
                flex-direction: column;
                gap: 24px 0;

                .collection-info{
                    border-bottom: 1px solid $gray-9;
                    padding-bottom: 24px;
                }
            }
        }
    }

    @media screen and (min-width: 0) and (max-width: $mobile) {
        .collection-container{
            padding: 12px 12px 48px;
            width: 100%;
            box-sizing: border-box;

            .collection-title{
                margin-bottom: 18px;
            }

            .collection-header{
                margin-bottom: 12px;

                .collection-header-img{    
                    height: calc(100vw - 32px);                
                    &.mobile{
                        display: initial;
                    }

                    &.default{
                        display: none;
                    }
                }
            }

            .collection-resources-container{
                column-gap: 0;
                margin: 18px 0;
                padding: 8px 0;

            
                .collection-resources-list{
                    flex-direction: row;
                    width: 100%;
                    flex-wrap: nowrap;
                    overflow-x: scroll;
                    
                }
            }
        }
    }

    @media screen and (min-width: $mobile) and (max-width: $tablet) {

        .collection-container{

            .collection-resources-container{

                .collection-resources-list{
                    gap: 24px;
                    justify-content: space-between;

                    .content-object .resource-thumbnail{
                        max-height: unset;
                    }
                }
            }
        }
        
    }
}