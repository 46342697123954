@import './variables.scss';

.contact-view{
    position: relative;
    width: 100vw;
    height: 100%;
    display: flex;

    .contact-container{
        position: relative;
        display: flex;
        flex-direction: row;
        min-width: fit-content;
        width: 70%;
        flex-wrap: nowrap;
        height: fit-content;
        margin: 80px auto auto;
        column-gap: 110px;

        .contact-info{
            display: flex;
            position: relative;
            flex-direction: column;
            max-width: 358px;

            h2{
                margin-bottom: 26px;
            }

            .contact-message{
                padding-bottom: 28px;
                border-bottom: 1px solid $gray-8;
                margin-bottom: 28px;
            }
        }

        .contact-form{
            position: relative;
            width: 100%;
            
            .input-container{
                flex-grow: 1;
                input, textarea{
                    width: 100%;
                }

                .cta-button{
                    font-weight: 700 !important;
                    width: fit-content;
                    padding: 8px 28px !important;
                    align-self: center;
                    display: block;
                    margin: 30px auto 0;
                }
            }


        }
    }
    @media screen and (min-width: 0) and (max-width: $mobile) {
        .contact-container{
            flex-direction: column;
            margin: 0;
            width: 100%;
            padding: 12px;
            box-sizing: border-box;

            span, h2{
                text-align: center;
            }

            .contact-info{
                border-bottom: 1px solid $gray-8;
                padding-bottom: 12px;
                margin-bottom: 20px;
                height: fit-content;
                min-height: 100%;
                .contact-message{
                    margin-bottom: 12px;
                    padding-bottom: 12px;
                }
            }

            .contact-form{
                height: fit-content;
                .input-container{
                    .cta-button{
                        margin-top: 12px;
                    }
                }
            }
        }
    }
}
.hs-form {
    input {
        margin-bottom: 15px;
    }

    .hs-error-msgs {
        color: $red-9;
        margin: 0;
    }

    .hs-form-required {
        display: none;
    }

    .cta-button {
        margin-top: 15px;
    }
}

.submitted-message{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}