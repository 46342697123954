@import './variables.scss';

.resource-view{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    background-color: $white-12;
    padding: 14px 0 0;
    box-sizing: border-box;
    flex: 1 1 auto;

    &.popup {
        overflow: hidden;
    }

    .resource-details-container{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: center;
        margin: 0 auto;
        box-sizing: border-box;
        column-gap: 40px;
        width: 100%;
        max-width: 1160px;
        padding: 0 80px 40px; 


        .resource-preview{
            position: relative;
            margin-right: 44px;
            max-width: 480px;
            width: 100%;
            box-sizing: content-box;
            margin: 12px 0 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: center;
            
            .resource-thumbnail{
                max-height: 480px;
                max-width: 480px;
                object-fit: contain;
                user-drag: none;
                -webkit-user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        }

        .info-container{
            position: relative;
            display: flex;
            flex-direction: column;
            width: 50%;

            .resource-title{
                font-weight: 700;
                margin: 0 0 16px;
            }
            .collection-container{
                display: flex;
                flex-direction: row;
                column-gap: 10px;
                .collection-title {
                    margin-bottom: 22px;
                    color: $gray-12;

                    a{
                        color: $blue-10;
                        border-bottom: 1px solid $blue-10;
                        font-weight: 700;
                    }
                }
            }

            .get-resource-container{
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                width: 100%;
                padding-bottom: 21px;
                border-bottom: 1px solid $gray-9;
                column-gap: 12px;
                margin-bottom: 6px;

                .get-option{
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    border-radius: 4px;
                    width: 100%;
                    box-sizing: border-box;
                    justify-content: space-between;
                    text-align: start;

                    &.download{
                        border-color: $green-10;

                        button{
                            border-color: $green-10;
                            background-color: $green-9;
                        }
                    }

                    &.order{
                        border-color: $blue-10;

                        button{
                            border-color: $blue-10;
                            background-color: $blue-9;
                        }
                    }

                    .option-info{
                        width: fit-content;
                    }

                    .option-actions{
                        position: relative;
                        height: fit-content;
                        align-self: flex-end;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        width: fit-content;
                    }
                    h4{
                        font-weight: 700;
                    }

                    ul{
                        padding-inline-start: 20px;
                        margin: 0 0 8px;
                    }

                    .option-cta{
                        color: $white-10;
                        border: 1px solid;
                        position: relative;
                        display: flex;
                        align-self: flex-end;
                        flex: 0 0 auto;
                        height: 72px;
                    }

                    .c1{
                        padding-right: 26px;
                    }

                    button{
                        padding: 7px 24px 13px;
                        font-weight: 700;
                        width: 100%;
                        justify-content: center;
                        color: $white-10;
                        margin-bottom: 12px;
                    }
                }
            }

            .resource-details{
                display: flex;
                flex-direction: column;
            }

            .resource-description{
                margin-bottom: 18px;
                padding-bottom: 12px;
                border-bottom: 1px solid $gray-9;
                box-sizing: border-box;
                h4{
                    font-weight: 700;
                    margin-bottom: 12px;
                }

                p{
                    color: $gray-13;
                }

            }

        }
    }
    .related-resources-container{
        display: flex;
        width: 100%;
        height: fit-content;
        padding: 32px 72px;
        background-color: $white-10;
        box-sizing: border-box;
        flex-direction: column;
        flex-wrap: nowrap;

        h3{
            color: $gray-12 ;
        }

        .related-resources-row{
            margin-top: 32px;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            column-gap: 24px;
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding: 12px 0;
        }
      
    }


    @media screen and (min-width: 0) and (max-width: 879px) {
        padding: 0;

        &.popup{
            //position: fixed;
            height: calc(100vh - 70px);
            //margin-top: 70px;         
            overflow: hidden;

           
        }

        .resource-details-container{
            flex-direction: column;
            width: 100%;
            max-width: 520px;
            padding: 20px;

            .resource-preview{
                margin: 0 auto 20px;
                width: 100%;

                .resource-thumbnail{
                    max-width: 100%;
                    max-height: unset;
                }
            }

            .info-container{
                width: 100%;
                max-width: unset;
                .resource-info-row{
                    max-width: 100%;
                    overflow-x: scroll;
                    .save, .share{
                        .c2{
                            display: none;
                        }
                    }
                }
                .get-resource-container{
                    flex-direction: column;
                    row-gap: 12px;
                    .get-option{
                        .option-cta{
                            height: 48px;
                        }
                    }
                }

                .collection-container svg{
                    display: none;
                }

                .resource-description{
                    margin-bottom: 0;
                }

                .resource-details{
                    padding: 20px 0;
                }
            }
        }

        .related-resources-container {
            padding: 16px 20px;

            .related-resources-row {
                margin-top: 24px;
                column-gap: 16px;
            }

        }
    }

    @media screen and  (min-width: 880px) and (max-width: $tablet) {
        .resource-container{
            padding: 0 20px;
            column-gap: 20px;

            .resource-preview{

                max-width: 400px;
                .resource-thumbnail{
                    max-height: 440px;
                    max-width: 400px;
                }
            }
        }
    }
}
.button-select-component{
    margin-bottom: 8px;
    .select-header{
        font-weight: 700;
    }

    .select-button{
        display: inline-flex;
        cursor: pointer;
        position: relative;
        background-color: $white-10;
        border: 1px solid $blue-10;
        color: $blue-10;
        margin: 8px 8px 8px 0 ;
        filter: $small_shadow;
        padding: 3px 8px 7px;

        &:last-child{
            margin-right: 0;
        }

        &:hover, &.selected{
            color: $white-10;
            background: $teal_blue_gradient
        }

        &.selected{
            pointer-events: none;
        }
    }
}

.language-select{
    margin: 12px auto 8px;


    .select-button{
        display: inline-flex;
        cursor: pointer;
        position: relative;
        border: none;
        border-radius: 0;
        color: $gray-12;
        margin: 8px 24px 8px 0 ;
        filter: $small_shadow;
        padding: 0 0 10px;
        font-weight: 500 !important;

        &:last-child{
            margin-right: 0;
        }

        &:hover, &.selected{
            color: $blue-10;
            border-bottom: 1px solid $blue-10;
        }

        &.selected{
            pointer-events: none;
        }
    }
}

.info-header{
    display: flex;
    flex-direction: column;
     &.mobile{
        display: none;
    }

    @media screen and (min-width: 0) and (max-width: 879px){

        &.mobile{
            display: flex;
            margin-bottom: 20px;
        }

        &.default{
           display: none;
        }
    }
}