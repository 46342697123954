@import '../variables.scss';
.onboarding-container{
    .alt-option{
        position: absolute;
        top: 24px;
        right: 24px;

        a{
            color: $blue-10;
            padding-bottom: 1px;
            border-bottom: 1px solid $blue-10;
        }
    }
}
.onboarding-info{
    .login-container{
        position: relative;
        flex-direction: column;
        margin: 15% auto 0;
        right: 0;
        left: 0;
        width: 500px;
        height: fit-content;
        align-items: center;
        padding: 24px;
        border-radius: 4px;

        h2{
            margin-bottom: 36px;
        }


        img{
            max-width: 117px;
        }
    }

    @media screen and (min-width: 0) and (max-width: $tablet){

        .login-container{
            padding: 16px 0;
            top: 0;
            bottom: 0;
            width: 100%;
        

            .login-cta{
                align-items: center;
                margin: 24px 16px;
                flex-direction: column;
                

                .login-message{
                    width: fit-content;
                    text-align: left;
                }
            }
        }
    }
}